import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './providers/auth-guard.service';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'medical-providers',
        loadChildren: () => import('./pages/medical-providers/medical-providers.module').then((m) => m.MedicalProvidersPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'representatives',
        loadChildren: () => import('./pages/representatives/representatives.module').then((m) => m.RepresentativesPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'documents',
        loadChildren: () => import('./pages/documents/documents.module').then((m) => m.DocumentsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'to-do',
        loadChildren: () => import('./pages/to-do/to-do.module').then((m) => m.ToDoPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'messaging',
        loadChildren: () => import('./pages/messaging/messaging.module').then((m) => m.MessagingPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'appointments',
        loadChildren: () => import('./pages/appointments/appointments.module').then((m) => m.AppointmentsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'library',
        loadChildren: () => import('./pages/library/library.module').then((m) => m.LibraryPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'help',
        loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'broadcast',
        loadChildren: () => import('./pages/broadcast/broadcast.module').then((m) => m.BroadcastPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'cpd',
        loadChildren: () => import('./pages/cpd/cpd.module').then((m) => m.CPDPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'clinical-trials',
        loadChildren: () => import('./pages/clinical-trials/clinical-trials.module').then((m) => m.ClinicalTrialsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'reporting',
        loadChildren: () => import('./pages/reporting/reporting.module').then((m) => m.ReportingPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'pharma-configuration',
        loadChildren: () => import('./pages/pharma-configuration/pharma-configuration.module').then((m) => m.PharmaConfigurationPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'logout',
        loadChildren: () => import('./pages/logout/logout.module').then((m) => m.LogoutPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        path: 'questionnaires',
        loadChildren: () => import('./pages/questionnaires/questionnaires.module').then( m => m.QuestionnairesPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: '**',
        redirectTo: '/login',
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
