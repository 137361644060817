import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AccessTokenService {
    private accessToken: string;

    constructor() {
    }

    setAccessToken(token) {
        this.accessToken = token;
    }

    getAccessToken() {
        return this.accessToken;
    }
}
