import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    states = ['state'];
    inputsState: { [key: string]: any } = {};

    loading$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.states.forEach(input => {
            this.inputsState[input] = {
                loading$: new BehaviorSubject(false),
                error$: new BehaviorSubject<string | null>(null)
            };
        }, {});
    }

    startLoading() {
        this.loading$.next(true);
    }

    stopLoading() {
        this.loading$.next(false);
    }

    // Managing multiple request states within a service
    setLoading(inputId: string, value: boolean) {
        this.inputsState[inputId].loading$.next(value);
    }

    setError(inputId: string, value: string | null) {
        this.inputsState[inputId].error$.next(value);
    }

    getLoading(inputId: string) {
        return this.inputsState[inputId].loading$.asObservable();
    }

    getError(inputId: string) {
        return this.inputsState[inputId].error$.asObservable();
    }
}
