import {Component, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

import {Menu} from './models/menu.model';
import {User} from './models/user.model';

import {HelperService} from './providers/helper.service';
import {AuthenticationService} from './providers/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;

    public user$: Observable<User> = null;
    public modules$: Observable<Menu[]>= null;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public translate: TranslateService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private helperService: HelperService,
        private menuCtrl: MenuController) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.user$ = this.authenticationService.user;
            this.modules$ = this.helperService.modules;
            
            this.translate.setDefaultLang(this.helperService.getAppLanguage());
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        this.menuCtrl.enable(true);
        this.helperService.setKeyValue('platform', this.platform.platforms());
        this.authenticationService.isLoggedIn().subscribe(isLoggedIn => {
            if(!isLoggedIn) {
                this.router.navigate(['/login']);
            }
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    onPageLoad(item, index) {
        this.selectedIndex = index;
        this.helperService.setModule(item);
        this.router.navigateByUrl(item.url);
    }
}
