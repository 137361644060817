import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../providers/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (this.authenticationService.loading) {
                    this.authenticationService.loading.dismiss();
                }

                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.router.navigate(['/login']);
                } else if (err.status === 500) {
                    this.authenticationService.console('error', err.error);
                    const buttons = [
                        {
                            text: 'OK',
                            handler: () => {

                            }
                        }
                    ];
                    this.authenticationService.alert(err.error.message || 'error_connecting_to_server', 'warning');
                } else {
                    const error = err.error.message || err.statusText;
                    return throwError(error);
                }
            })
        );
    }
}
