import {Injectable, Output} from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    ModalController,
    Platform,
    ToastController
} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {last, map, take, tap, timeout} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Menu} from '../models/menu.model';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Capacitor} from '@capacitor/core';
import * as moment from 'moment';
import {AccessTokenService} from './access-token.service';
import {LocationService} from './location.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    @Output() modalData: any;
    private moduleList: any;

    public modal: any;
    public loading: any;
    public language: string;
    public serviceState: any;
    public environment = environment;
    public module$: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);
    public modules$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
    public refreshCharts$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public uploadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public downloadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public http: HttpClient,
        public translateService: TranslateService,
        public modalController: ModalController,
        public actionSheetController: ActionSheetController,
        public toastController: ToastController,
        public documentSharing: SocialSharing,
        public locationService: LocationService,
        public platform: Platform,
        public alertController: AlertController,
        public loadingController: LoadingController,
        public tokeService: AccessTokenService,
        public accessTokenService: AccessTokenService,
        public permissionsService: NgxPermissionsService) {
    }

    post(url, postData = {}, loadingMessage = null, errorMessage = null, showErrorMessage = true) {
        if (loadingMessage != null) {
            this.console('log', 'in loading 1');
            this.showLoading(loadingMessage);
        }
        const body = JSON.stringify(postData);
        const startFrom = new Date().getTime(); // get http execution time

        console.log(
            '%c' + url + ' %cPOST body here ->',
            'color:green;font-weight:bold;',
            'color:black',
            body
        );

        return this.http
            .post(environment.api + url, postData, {observe: 'response'})
            .pipe(timeout(60000))
            .toPromise()
            .then(
                (res: any) => {
                    if (loadingMessage != null) {
                        this.loading.dismiss();
                    }
                    console.log(
                        '%c' + url + ' %cresult here ->',
                        'color:green;font-weight:bold;',
                        'color:black',
                        res,
                        new Date().getTime() - startFrom + 'ms'
                    );

                    return res.body;
                },
                (err) => {
                    console.log('post ERROR -> ', err);
                    const message =
                        errorMessage !== null ? errorMessage : 'error_connecting_to_server';
                    if (loadingMessage != null) {
                        this.loading.dismiss();
                    }
                    if (showErrorMessage) {
                        this.alert(message);
                    }
                    // this.logError(err.status,body,url,err,(new Date().getTime() - startFrom));
                }
            );
    }

    put(url, postData = {}, loadingMessage = null, errorMessage = null, showErrorMessage = true) {
        if (loadingMessage != null) {
            console.log('in loading 1');
            this.showLoading(loadingMessage);
        }
        const body = JSON.stringify(postData);
        const startFrom = new Date().getTime(); // get http execution time

        console.log(
            '%c' + url + ' %cPOST body here ->',
            'color:green;font-weight:bold;',
            'color:black',
            body
        );

        return this.http
            .put(environment.api + url, postData, {observe: 'response'})
            .pipe(timeout(60000))
            .toPromise()
            .then(
                (res: any) => {
                    if (loadingMessage != null) {
                        this.loading.dismiss();
                    }
                    console.log(
                        '%c' + url + ' %cresult here ->',
                        'color:green;font-weight:bold;',
                        'color:black',
                        res,
                        new Date().getTime() - startFrom + 'ms'
                    );

                    return res.body;
                },
                (err) => {
                    console.log('post ERROR -> ', err);
                    const message = errorMessage !== null ? errorMessage : 'error_connecting_to_server';
                    if (loadingMessage != null) {
                        this.loading.dismiss();
                    }
                    if (showErrorMessage) {
                        this.alert(message);
                    }
                }
            );
    }

    get(url, data = {}, loadingMessage = null) {
        if (loadingMessage != null) {
            console.log('in loading 1');
            this.showLoading(loadingMessage);
        }
        const startFrom = new Date().getTime(); // get http execution time

        console.log(
            '%c' + url + ' %cGET body here ->',
            'color:green;font-weight:bold;',
            'color:black',
            data
        );

        return this.http
            .get(environment.api + url)
            .pipe(timeout(60000))
            .toPromise()
            .then((res: any) => {
                if (loadingMessage != null) {
                    this.loading.dismiss();
                }
                console.log(
                    '%c' + url + ' %cresult here ->',
                    'color:green;font-weight:bold;',
                    'color:black',
                    res,
                    new Date().getTime() - startFrom + 'ms'
                );
                return res;
            })
            .catch((error: any) => {
            });
    }

    delete(url: string, loadingMessage: string = null) {
        return this.http
            .delete(environment.api + url)
            .pipe(timeout(60000))
            .toPromise()
            .then((res: any) => {
                if (loadingMessage != null) {
                    this.loading.dismiss();
                }
                return res;
            })
            .catch((error: any) => {
            });
    }

    async presentAlertConfirm(header: string, message: string, buttons: any = ['OK']) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: this.translateService.instant(header),
            message: this.translateService.instant(message),
            buttons,
        });
        await alert.present();
    }

    async presentActionSheet(header: string, cssClass: string, buttons: any) {
        const actionSheet = await this.actionSheetController.create({
            header: this.translateService.instant(header),
            buttons,
        });
        await actionSheet.present();
    }

    async alert(messageText, messageHeader = null, buttons: any = ['OK']) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: this.translateService.instant(messageHeader),
            message: this.translateService.instant(messageText),
            buttons,
        });
        return await alert.present();
    }

    async showLoading(loadingMessage, duration = 5000) {
        this.loading = await this.loadingController.create({
            message: this.translateService.instant(loadingMessage),
            duration
        });
        return await this.loading.present();
    }

    async presentModal(ModalPage, data = {}, callback = null, customClass = 'general-modal-1') {
        this.modal = await this.modalController.create({
            component: ModalPage,
            cssClass: customClass,
            componentProps: {
                data
            },
            swipeToClose: true,
            backdropDismiss: false
        });

        this.modal.onDidDismiss((dataReturned) => {
            if (dataReturned !== null) {
                this.modalData = dataReturned.data;
            }
        });

        return await this.modal.present();
    }

    async presentModalWithCallback(ModalPage, data = {}, customClass = 'general-modal-1') {
        this.modal = await this.modalController.create({
            component: ModalPage,
            cssClass: customClass,
            componentProps: data,
            backdropDismiss: false
        });

        return await this.modal;
    }

    async dismissModal(data = null) {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modal.dismiss(data);
    }

    async presentAlertPrompt(header: string, message: string, inputs: any, buttons: any) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: this.translateService.instant(header),
            message: this.translateService.instant(message),
            inputs,
            buttons,
        });

        return await alert.present();
    }

    async showMessage(header: string, message: string, position = 'bottom', duration: number = 3000, close: boolean = false) {
        if(header){
            const toast = await this.toastController.create({
                header: this.translateService.instant(header),
                message: this.translateService.instant(message),
                position: 'middle',
                duration,
                buttons: [{
                    side: 'end',
                    text: this.translateService.instant('close'),
                    role: 'cancel',
                    handler: () => {
                    }
                }]
            });
            await toast.present();
        }else{
            const toast = await this.toastController.create({
                message: this.translateService.instant(message),
                position: 'middle',
                duration,
                buttons: [{
                    side: 'end',
                    text: this.translateService.instant('close'),
                    role: 'cancel',
                    handler: () => {
                    }
                }]
            });
            await toast.present();
        }
    }

    public getInitData() {
        return this.getRequest('init-data')
            .pipe(
                take(1),
                map((response: any) => {
                    if (response.success) {
                        this.setKeyValue('initData', response.data);
                        this.moduleList = response.data.modules;
                        this.modules$.next(this.moduleList);
                    }
                    return response.data;
                }));
    }

    public getKeyValue(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    public setKeyValue(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public removeKeyValue(key) {
        localStorage.removeItem(key);
    }

    public isNative() {
        return Capacitor.isNative;
    }

    public console(key, value) {
        if (this.environment.debug) {
            console.log('%c' + key + ' %cGET body here ->', 'color:green;font-weight:bold;', 'color:black', value);
        }
    }

    switchLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem('language', language);
        this.language = language;
    }

    getAppLanguage() {
        this.language = localStorage.language;
        return this.language || 'en';
    }

    getDob(idNumber) {
        const Year = idNumber.substring(0, 2);
        const Month = idNumber.substring(2, 4);
        const Day = idNumber.substring(4, 6);

        const cutoff = (new Date()).getFullYear() - 2000;
        const dob = (Year > cutoff ? '19' : '20') + Year + '/' + Month + '/' + Day;
        return dob;
    }

    getGender(idNumber, data) {
        // get the gender
        const genderCode = idNumber.substring(6, 10);
        const gender = parseInt(genderCode) < 5000 ? 'female' : 'male';
        const index = data.findIndex(x => x.description.toLowerCase() === gender);
        return data[index].id;
    }

    validateID(idNumber) {
        // Apply Luhn formula for check-digits
        let tempTotal = 0;
        let checkSum = 0;
        let multiplier = 1;
        for (let i = 0; i < 13; ++i) {
            tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
            if (tempTotal > 9) {
                tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
            }
            checkSum = checkSum + tempTotal;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
        }

        return (checkSum % 10) === 0;
    }

    isTesting() {
        return this.environment.debug;
    }

    getPlatform() {
        return this.platform.platforms();
    }

    postRequest(url, data) {
        data.location = this.locationService.coordinates;
        return this.http
            .post(`${environment.api}${url}`, this.cleanPostData(data), {observe: 'response'})
            .pipe(take(1), map((response: any) => response.body));
    }

    getRequest(url) {
        return this.http.get(`${environment.api}${url}`).pipe(take(1));
    }

    putRequest(url: string, data: any) {
        data.location = this.locationService.coordinates;
        return this.http
            .put(`${environment.api}${url}`, this.cleanPostData(data), {observe: 'response'})
            .pipe(take(1), map((response: any) => response.body));
    }

    deleteRequest(url, data = null) {
        return this.http.delete(`${environment.api}${url}`).pipe(timeout(3000));
    }

    pick(obj, props) {
        // Make sure object and properties are provided
        if (!obj || !props) {
            return;
        }

        // Create new object
        const picked = {};

        // Loop through props and push to new object
        props.forEach((prop) => {
            picked[prop] = obj[prop];
        });

        return picked;
    }

    postRequestWithProgressReport(url, data) {
        const req = new HttpRequest('POST', `${environment.api}${url}`, this.cleanPostData(data), {
            responseType: 'arraybuffer',
            reportProgress: true
        });

        return this.http.request(req).pipe(
            map(event => this.getStatusMessage(event)),
            tap(message => this.console('postRequestWithProgressReport', message)),
            last()
        );
    }

    base64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteArrays = [];
        const byteCharacters = atob(b64Data);

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    convertBase64ToBlob(base64Image: string) {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], {type: imageType});
    }

    // For native Upload
    imageFileUpload(url: string, data: any, blob: Blob, filename = 'image.jpg') {
        return new Observable((observer) => {
            const request = new XMLHttpRequest();
            const formData = new FormData();

            // Set and delete token
            const accessToken = this.tokeService.getAccessToken();

            formData.append('file', blob, filename);
            for (const [key, value] of Object.entries(data)) {
                formData.append(`${key}`, `${value}`);
            }

            formData.append('location', JSON.stringify(this.locationService.coordinates));

            request.open('POST', `${environment.api}${url}`, true);

            request.setRequestHeader('Authorization', `Bearer ${accessToken}`);

            request.upload.addEventListener('progress', (e) => {
                const status = Math.round(100 * e.loaded / e.total);
                this.uploadProgress.next(status);
            });

            request.onreadystatechange = () => {
                if (request.readyState === 4) {
                    if (request.status === 200 || request.status === 201) {
                        observer.next(JSON.parse(request.response));
                    } else {
                        observer.error(JSON.parse(request.response));
                    }
                    this.loading.dismiss();
                }
            };

            request.send(this.cleanPostData(formData));
        });
    }

    // For browser upload
    uploadFile(url, data) {
        return new Observable((observer) => {
            const reader = new FileReader();
            reader.onload = () => {
                const request = new XMLHttpRequest();
                const formData = new FormData();

                // Set and delete token
                const accessToken = this.tokeService.getAccessToken();

                const imgBlob = new Blob([reader.result], {
                    type: data.file.type
                });

                formData.append('file', imgBlob, data.file.name);
                for (let [key, value] of Object.entries(data)) {
                    if (value instanceof Array) {
                        value = JSON.stringify(value);
                    }
                    formData.append(`${key}`, `${value}`);
                }

                formData.append('location', JSON.stringify(this.locationService.coordinates));

                this.console('uploadFile -> formData', formData);

                request.open('POST', `${environment.api}${url}`, true);
                request.setRequestHeader('Authorization', `Bearer ${accessToken}`);

                request.upload.addEventListener('progress', (e) => {
                    const status = Math.round(100 * e.loaded / e.total);
                    this.uploadProgress.next(status);
                });

                request.onreadystatechange = () => {
                    if (request.readyState === 4) {
                        if (request.status === 200 || request.status === 201) {
                            observer.next(JSON.parse(request.response));
                        } else {
                            observer.error(JSON.parse(request.response));
                        }
                    }
                };
                request.send(this.cleanPostData(formData));
            };
            reader.readAsArrayBuffer(data.file);
        });
    }

    getRequestWithProgressReport(url) {
        const req = new HttpRequest('GET', `${environment.api}${url}`, null, {
            reportProgress: true
        });

        return this.http.request(req).pipe(
            map(event => this.getStatusMessage(event)),
            tap(message => this.console('getRequestWithProgressReport', message)),
            last()
        );
    }

    getStatusMessage(event) {
        let status;

        switch (event.type) {
            case HttpEventType.Sent:
            case HttpEventType.UploadProgress:
                status = Math.round(100 * event.loaded / event.total);
                this.uploadProgress.next(status);
                return `${status}% uploaded`;
            case HttpEventType.DownloadProgress:
                status = Math.round(100 * event.loaded / event.total);
                this.downloadProgress.next(status); // NOTE: The Content-Length header must be set on the server to calculate this
                return `${status}% downloaded`;
            case HttpEventType.Response:
                return `Done`;
            default:
                return `Something went wrong`;
        }
    }

    setChartRefreshStatus(status) {
        this.refreshCharts$.next(status);
    }

    get refreshStatus() {
        return this.refreshCharts$.asObservable();
    }

    resetProgress() {
        this.uploadProgress.next(0);
        this.downloadProgress.next(0);
    }

    /*
    Setters
     */
    setModule(module) {
        this.module$.next(module);
    }

    setModules(modules) {
        this.modules$.next(modules);
    }

    /*
    Getters
     */
    get module() {
        return this.module$.asObservable();
    }

    get modules() {
        return this.modules$.asObservable();
    }

    get today() {
        return new Date().toISOString();
    }

    socialSharing(document) {
        return this.documentSharing.share(document.title, document.title, null, document.url);
    }

    checkPasswordStrength(password) {
        return this.postRequest(`check-password-strength`, {password}).pipe(take(1));
    }

    cleanPostData(item) {
        if (!item) {
            return item;
        }

        for (const [key, value] of Object.entries(item)) {
            if (item.hasOwnProperty(key) && (value === null || value === '')) {
                delete item[key];
            }
        }

        return item;
    }

    momentDate(date, format = null) {
        if (format) {
            return moment(date).format(format);
        }
        return moment(date);
    }

    convertDateFromString(str) {
        const date = new Date(str);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return [date.getFullYear(), month, day].join('-');
    }

    convertTimeFromString(str) {
        const date = new Date(str);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        return [hours, minutes].join(':');
    }

    setServiceState(state: any) {
        this.serviceState = state;
    }

    async createBlobFromFileUrl(url) {
        const response = await fetch(url);
        return await response.blob();
    }

    updateInitData(key, data) {
        const initData = this.getKeyValue('initData');
        initData[key] = data;
        this.setKeyValue('initData', initData);
    }

    getRTEConfig() {
        return {
            toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'indent', 'outdent'],
            placeholder: this.translateService.instant('paste_or_edit_package_insert')
        };
    }
}
