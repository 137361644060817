import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {TokenInterceptor} from './helpers/token.interceptor';
import {NgxPermissionsModule} from 'ngx-permissions';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Calendar} from '@ionic-native/calendar/ngx';
import {NgxOneSignalModule} from 'ngx-onesignal';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
import {LoadingInterceptor} from './helpers/loading.interceptor';
import {CalendarModule} from 'ion2-calendar';
import {NgCalendarModule} from 'ionic2-calendar';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {File} from '@ionic-native/file/ngx';
import {PreviewAnyFile} from '@ionic-native/preview-any-file/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FileTransfer, FileTransferObject} from '@awesome-cordova-plugins/file-transfer/ngx';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, `${environment.api}translations/`, '');
}

defineCustomElements(window);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('OneSignalSDKWorker.js', {enabled: environment.production}),
        NgxPermissionsModule.forRoot(),
        NgxOneSignalModule.forRoot({appId: environment.oneSignalAppID}),
        CalendarModule.forRoot({
            doneLabel: 'Save',
            closeIcon: true
        }),
        NgCalendarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        Ng2SmartTableModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        OneSignal,
        Calendar,
        Geolocation,
        NativeGeocoder,
        SocialSharing,
        FileTransfer,
        FileTransferObject,
        File,
        Camera,
        PreviewAnyFile,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
