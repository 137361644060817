import {Injectable} from '@angular/core';
import {HelperService} from './helper.service';
import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {LoginRequest, PasswordResetRequest, RegisterRequest} from '../models/requests.model';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    ModalController, Platform,
    ToastController
} from '@ionic/angular';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {AccessTokenService} from './access-token.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends HelperService {
    private COUNTRY_CODE = 'za';
    private authenticatedUser: any;
    private AUTHENTICATED_USER = 'authenticated_user';
    private userSub = new BehaviorSubject<any>(null);
    private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get user() {
        return this.userSub.asObservable();
    }

    updateUserData(data) {
        this.userSub.next({...this.authenticatedUser, ...data});
    }

    login(data: LoginRequest) {
        return this.postRequest(`login`, data)
            .pipe(
                take(1),
                map((response: any) => {
                    if (response.success) {
                        this.authenticatedUser = response.data;
                        this.userSub.next(this.authenticatedUser);
                        this.isAuthenticated.next(true);
                        this.accessTokenService.setAccessToken(response.data.access_token);
                        this.permissionsService.loadPermissions(response.data.permissions);
                    }
                    return response;
                }));
    }

    updateUser(data) {
        return this.postRequest(`profile`, data)
            .pipe(take(1),
                map((response: any) => {
                    if (response.success) {
                        this.authenticatedUser = {...this.authenticatedUser, ...data};
                        this.userSub.subscribe(user => {
                            this.userSub.next({...user, ...data});
                        });
                    }
                    return response;
                }));
    }

    register(data: RegisterRequest) {
        return this.postRequest(`register`, data);
    }

    logout(): void {
        localStorage.removeItem(this.AUTHENTICATED_USER);
        this.isAuthenticated.next(false);
    }

    passwordReset(data: PasswordResetRequest) {
        return this.postRequest(`password/email`, data);
    }

    isLoggedIn() {
        return this.isAuthenticated.asObservable();
    }

    setUser(data) {
        this.setKeyValue(this.AUTHENTICATED_USER, data);
    }

    getCountryCode() {
        return this.COUNTRY_CODE;
    }

    getUser(property = null) {
        if (property) {
            return this.authenticatedUser[property];
        }
        return this.authenticatedUser;
    }
}
