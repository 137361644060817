// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /*  production: false,
    api: 'https://api.healthdocs.co.za/healthdocs/pharma/staging/k/public/',
    debug: true,
    oneSignalAppID: 'c7980459-c449-46ce-9199-940f772c2c35'*/
    production: true,
    api: 'https://api.healthdocs.co.za/healthdocs/pharma/production/1.0.0/public/',
    debug: false,
    oneSignalAppID: '106b0a07-7334-4931-900d-826da1f1648b'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a nvm use v16
 * negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
