import {Injectable} from '@angular/core';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private location: Coordinates;

    constructor(private geolocation: Geolocation) {
    }

    getCurrentPosition() {
        this.geolocation.getCurrentPosition().then((resp) => {
            this.location = resp.coords;
        });
    }

    watchPosition() {
        const watch = this.geolocation.watchPosition();

        watch.subscribe((data: any) => {
            this.location = data.coords;
        });
    }

    get coordinates() {
        return {
            latitude: this.location?.latitude,
            longitude: this.location?.longitude,
        };
    }
}
